// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/home/runner/work/Site/Site/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-members-js": () => import("/home/runner/work/Site/Site/src/templates/members.js" /* webpackChunkName: "component---src-templates-members-js" */),
  "component---src-templates-publications-js": () => import("/home/runner/work/Site/Site/src/templates/publications.js" /* webpackChunkName: "component---src-templates-publications-js" */),
  "component---src-templates-research-projects-js": () => import("/home/runner/work/Site/Site/src/templates/research-projects.js" /* webpackChunkName: "component---src-templates-research-projects-js" */),
  "component---src-templates-vacancies-js": () => import("/home/runner/work/Site/Site/src/templates/vacancies.js" /* webpackChunkName: "component---src-templates-vacancies-js" */),
  "component---src-pages-404-js": () => import("/home/runner/work/Site/Site/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contacts-js": () => import("/home/runner/work/Site/Site/src/pages/contacts.js" /* webpackChunkName: "component---src-pages-contacts-js" */),
  "component---src-pages-index-js": () => import("/home/runner/work/Site/Site/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-members-js": () => import("/home/runner/work/Site/Site/src/pages/members.js" /* webpackChunkName: "component---src-pages-members-js" */),
  "component---src-pages-publications-js": () => import("/home/runner/work/Site/Site/src/pages/publications.js" /* webpackChunkName: "component---src-pages-publications-js" */),
  "component---src-pages-research-projects-js": () => import("/home/runner/work/Site/Site/src/pages/research-projects.js" /* webpackChunkName: "component---src-pages-research-projects-js" */),
  "component---src-pages-vacancies-js": () => import("/home/runner/work/Site/Site/src/pages/vacancies.js" /* webpackChunkName: "component---src-pages-vacancies-js" */)
}

