module.exports = [{
      plugin: require('/home/runner/work/Site/Site/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":900},
    },{
      plugin: require('/home/runner/work/Site/Site/node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography","omitGoogleFont":true},
    },{
      plugin: require('/home/runner/work/Site/Site/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-149415782-1"},
    },{
      plugin: require('/home/runner/work/Site/Site/node_modules/gatsby-plugin-yandex-metrika/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"55613563","clickmap":true,"trackLinks":true,"trackHash":true,"accurateTrackBounce":true,"webvisor":true},
    },{
      plugin: require('/home/runner/work/Site/Site/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/home/runner/work/Site/Site/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
